@use "colors";

$shadow: rgba(0, 0, 0, .1);
$shadow-length: 4px;

.circle {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 0;
	padding: 50% 0%;
	border-radius: 50%;
	/* Just making it pretty */
	-webkit-box-shadow: 0 $shadow-length 0 0 $shadow;
	box-shadow: 0 $shadow-length 0 0 $shadow;
	text-shadow: 0 $shadow-length 0 $shadow;

	background-color: #dcedf9;
	background-size: cover;

	color: white;
	text-align: center;
	transition: background 0.5s, color 0.2s;
}

.circle h1 {
	transform: translate(0, -50%);
}

.circle:hover, .circle:focus {
	color: colors.$red;
	text-decoration: none;
	/*! transition: 0.5s all; */
	-webkit-transition: 0.5s all;
	-o-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-ms-transition: 0.5s all;
}
/*
.circle:after {
	top: 20px;
	left: 20px;
	box-shadow: 0 0 0 2px #FFFFFF;
	transition: background 0.5s, color 0.2s;
	transform: scale(0.8);

	width: 200px;
	height: 200px;
	pointer-events: none;
	content: ' ';
	position: absolute;
}
*/
