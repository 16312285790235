$light-red: #f1c0b9;
$light-green: #d2fba4;

.month {
	display: inline-table;
	max-width: 300px;
	margin: 10px;
}

.month tr td,th {
	text-align: center;
}

.month tr td.bothways {
	background-image: linear-gradient(to right bottom, $light-green 50%, $light-red 50%);
}

.month tr td.there {
	background-color: $light-green;
}

.month tr td.return {
	background-color: $light-red;
}

.month tr td.double {
	font-weight: bold;
}

.month-legend {
	max-width: 100%;
}

.month-legend tr td.text {
	text-align: left;
}
