@use 'colors';

form fieldset label {
	display: inline-block;
	width: 25%;
	text-align: right;
	vertical-align: top;
}

form em {
	color: colors.$red;
}

form ol {
	margin: 0;
	padding: 0;
}

form ol li {
	list-style: none;
	padding: 5px;
	margin: 0;
}

select, input, textarea {
	box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
	-moz-box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
	-webkit-box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
	//background: -webkit-gradient(linear, left top, left 25, from($blue), to(#FFFFFF));
	//background: -moz-linear-gradient(top, $blue, #FFFFFF 25px);
	//background: $blue;
}

input:hover, textarea:hover, select:hover,
input:focus, textarea:focus, select:focus{
	-webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
}

/*input.submit, button.submit {
	width: auto;
	padding: 9px 15px;
	background: $blue;
	border: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
}*/
