@use 'colors';

body {
	color: colors.$grey;
	background: colors.$sky;
	font-size: 110%;
}

.container {
	padding-right: 1em;
	padding-left: 1em;
	display: flex;
	justify-content: space-between;
}

.vertical {
	flex-direction: column;
}

@media (max-width:1000px) {
	.container {
		flex-direction: column;
	}
}

/* ======================== flex ============================*/

.flexbox {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.flexitem {
	flex-basis: 350px;
	flex-grow: 1;
	margin: 1em;
	max-width: 430px;
}

/* ======================== table ===========================*/

$text-width: 320px;
$date-width: 320px;
$icon-width: 200px;
$num-width: 150px;

.table {
	width: 100%;
}

.table-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	flex-grow: 0;
	padding: 1em;
}

.table-striped > .table-row:nth-of-type(odd) {
	background-color: colors.$grey-f9;
}

.table > .header {
	font-weight: bold;
	background-color: colors.$grey-f9;
}

.table-column {
	flex-grow: 0;
	flex-shrink: 0;
	vertical-align: top;
	flex-wrap: wrap;
}

.text {
	flex-grow: 1;
	width: $text-width;
}

.num {
	width: $num-width;
}

.date {
	width: $date-width;
}

.icon {
	width: $icon-width;
}

table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	background-color: transparent;
	border-spacing: 0;
	border-collapse: collapse;
}

thead > tr:nth-of-type(odd),
tbody > tr:nth-of-type(even) {
	background-color: colors.$grey-f9;
}

tbody > tr:hover {
	background-color: colors.$grey-f5;
}

thead > tr > th {
	vertical-align: bottom;
	border-bottom: 2px solid #ddd;
}

/* ======================== header ===========================*/
.title {
	text-align: center;
	background: colors.$sky;
	padding: 2em;
}

.title p {
	margin: 1em 0 0 0;
}

/* ======================== slider ===========================*/
.slider::before, .slider::after{
	content: "";
	background-position: center;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
}
/* the blurred image */
.slider::before{
	background-size: cover;
	filter: blur(15px);
}
/* the clear image */
.slider::after{
	background-repeat: no-repeat;
	background-size: contain;
	z-index: 1;
}
.kb_elastic .item
{
	top:0;
	z-index:1;
	opacity:0;
	width:100%;
	position:absolute;
	left:0!important;
	display:block!important;
	-webkit-transition:-webkit-transform .7s ease-in-out 0s,opacity ease-in-out .7s;
	transition:transform .7s ease-in-out 0s,opacity ease-in-out .7s
}
.kb_elastic .item.active
{
	opacity:1;
	z-index:2;
	-webkit-transition:-webkit-transform .7s ease-in-out 0s,opacity ease-in-out .7s;
	transition:transform .7s ease-in-out 0s,opacity ease-in-out .7s
}

@for $i from 1 through 12 {
	.col-#{$i} {
		width: calc(100% / 12) * $i;
	}
	.col-left-#{$i} {
		width: calc(100% / 12) * $i;
		float: left;
	}
	.col-right-#{$i} {
		width: calc(100% / 12) * $i;
		float: right;
	}
	.col-center-#{$i} {
		width: calc(100% / 12) * $i;
		margin: 0 auto;
	}
}

h1 {
	font-weight: normal;
	font-size: 3em;
	margin: 0;
	font-family: 'Pacifico';
}

h3, h4 {
	font-weight: 600;
}

.footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 2em 0;
	margin: 2em 0 0;
	background: white;
	color: colors.$grey-9;
}

.footer a {
	color: colors.$grey-9;
	text-decoration: none;
}

.footer a:hover{
	color: colors.$red;
}

.footer > div {
	flex-shrink: 0;
	flex-grow: 1;
	max-width: 400px;
	padding: 0 0.5em;
}
