$slider-height: 650px;

.slider-grid p {
	color: white;
	margin: 1em auto 0;
}

.slider-grid h1 {
  color: white;
}

.slider {
	background-repeat: no-repeat;
	background-size: cover;
	height: $slider-height;
}

.slider-grid{
	width: 290px !important;
    top: 23%;
    position: absolute;
    height: 290px;
    margin: 3em auto 0;
    text-align: center;
    border: solid 2px #fff;
    padding: 5em 0 0 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
}
