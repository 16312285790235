.top-menu {
	background: url(/images/8.jpg) no-repeat 0px 0px;
	background-size: auto;
	background-size: cover;
	min-height: 130px;
	padding: 0 1em;
	z-index: 99;
	color:#fff;
}

.top-menu a {
	color:#fff;
	text-decoration: none;
}

.top-menu a:hover{
	color:#fd5c63;
}

.with-slider {
	position: absolute;
	width: 100%;
	left: 50%;
	background: none;
	transform: translate(-50%, 0);
}

.top-banner {
	display: flex;
	padding: .5em 0;
	font-size:.875em;
	border-bottom: solid 1px rgba(255, 255, 255, 0.23);
}

@media (max-width:600px) {
	.top-banner {
		font-size:.7em;
	}
}

.top-banner-left {
	display: flex;
	flex-grow: 1;
}

.top-banner-left > div {
	margin-left: 1em;
}

.top-banner-right {
	display: flex;
}

.top-banner-right > div {
	margin-right: 1em;
}

.top-logo-nav {
	display: flex;
	padding: 0.5em 0 1em 0;
	align-items: center;
}

.logo {
	align-self: flex-start;
	flex-shrink: 0;
	width: 15em;
}

.top-nav {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

.top-navbar {
	display: flex;
	justify-content: flex-end;
}

.top-navbar > div {
	margin: 1em;
}

@media (max-width:1000px) {
	.top-navbar {
		flex-direction: column;
	}

	.top-navbar > div {
		margin: 0em;
	}
}

