@use "colors";

$padding-in-canvas: 1em;

.canvas {
	padding: $padding-in-canvas;
}

.white-canvas {
	background-color: white;
	padding-bottom: $padding-in-canvas;
	width: 100%;
}

.white-canvas  img {
	max-width: 60em;
	width: 100%;
}

.white-canvas h2 {
	color: colors.$red;
	margin-top: 0;
	font-weight: 0;
}

.white-canvas-left {
	order: 1;
	padding-left: $padding-in-canvas;
	padding-top: $padding-in-canvas;
	padding-bottom: $padding-in-canvas;
}

.white-canvas-text {
	order: 2;
	flex-grow: 1;
	padding: $padding-in-canvas;
}

.white-canvas-right {
	order: 3;
	padding-right: $padding-in-canvas;
	padding-top: $padding-in-canvas;
	padding-bottom: $padding-in-canvas;
}
